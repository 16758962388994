/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import "./../App.css";
import logo from "./../images/logo.png";
// import RegisterModal from "../components/Modal.jsx";
// import LoginModal from "../components/LoginModal.jsx";
// import LoginSinupModal from "../components/Login_sinup_modal.jsx";
// import OtpModal from "../components/Otpmodal.jsx";
import { Link } from "react-router-dom";
// import { UserContext } from "../context/userContext.js";
// import DailogBox from "../components/DailogBox.jsx";
import { toast, ToastContainer } from "react-toastify";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

export default function Header({
  getID,
  items,
  toggleDropdown,
  toggleModal,
  closeMenu,
}) {
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [modalIsOpen2, setModalIsOpen2] = useState(false);
  // // const [modalIsOpen3, setModalIsOpen3] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [open, setOpen] = useState(false); // Initialize with false
  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // toggle dropdown visibity

  // const toggleDropdown = () => {
  //   setDropdownOpen((prev) => !prev);
  // };

  // const toggleModal = () => {
  //   setIsModalOpen(!isModalOpen);
  // };

  // Function to toggle the menu

  // const closeMenu = () => {
  //   setOpen(false);
  // };
  // const [items, setItems] = useState([]);
  // const getID = localStorage.getItem("users");

  // useEffect(() => {
  //   setItems(JSON.parse(getID));
  // }, [getID]);

  //logout funtionality

  // const handleLogout = () => {
  //   localStorage.removeItem("users");
  //   toast.success("You are successfully logged out", {
  //     duration: 1000,
  //     position: "bottom-right",
  //   });
  //   setItems(null);
  //   setDropdownOpen(false);
  // };

  return (
    <>
      <ToastContainer />
      <header className="fixed top-0 left-0 z-[100] w-full shadow-lg text-white bg-primary-blue px-2 md:px-5 py-3 flex flex-row justify-between items-center">
        <div className="w-1/2">
          <a href="index.html">
            {/* <img src={logo} alt="Logo" />{" "} */}
            <span className="font-bold text-2xl md:text-3xl">Scholar.AI</span>
          </a>
        </div>
        <div className="md:hidden">
          <Menu>
            <MenuButton>My account</MenuButton>
            <MenuItems anchor="bottom" className="bg-white flex flex-col gap-2">
              <MenuItem>
                <Link
                  to="#"
                  className="menu-link px-2 hover:text-primary-orange"
                >
                  Home
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="#"
                  className="menu-link px-2 hover:text-primary-orange"
                >
                  Features
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="#"
                  className="menu-link px-2 hover:text-primary-orange"
                >
                  Pricing
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="#"
                  className="menu-link px-2 hover:text-primary-orange"
                >
                  Testimonials
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="#"
                  className="menu-link px-2 hover:text-primary-orange"
                >
                  Blog
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="#"
                  className="menu-link px-2 hover:text-primary-orange"
                >
                  Contact Us
                </Link>
              </MenuItem>
              <MenuItem>
                {getID && items && items.name ? (
                  <span
                    className="menu-link cursor-pointer"
                    onClick={toggleDropdown}
                  >
                    {items.name}
                  </span> // Display user name if logged in
                ) : (
                  <Link
                    to="/"
                    className="menu-link px-2"
                    onClick={() => {
                      toggleModal();
                      closeMenu();
                    }}
                  >
                    Login / SignUp
                  </Link>
                )}
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
        <div className="hidden md:block">
          <ul className="text-white flex gap-1 md:gap-5 border-0">
            <li>
              <Link to="#" className="menu-link hover:text-primary-orange">
                Home
              </Link>
            </li>
            <li>
              <Link to="#" className="menu-link hover:text-primary-orange">
                Features
              </Link>
            </li>
            <li>
              <Link to="#" className="menu-link hover:text-primary-orange">
                Pricing
              </Link>
            </li>
            <li>
              <Link to="#" className="menu-link hover:text-primary-orange">
                Testimonials
              </Link>
            </li>
            <li>
              <Link to="#" className="menu-link hover:text-primary-orange">
                Blog
              </Link>
            </li>
            <li>
              <Link to="#" className="menu-link hover:text-primary-orange">
                Contact Us
              </Link>
            </li>
            <li className="relative hover:text-primary-orange font-semibold">
              {getID && items && items.name ? (
                <span
                  className="menu-link cursor-pointer"
                  onClick={toggleDropdown}
                >
                  {items.name}
                </span> // Display user name if logged in
              ) : (
                <Link
                  to="/"
                  className="menu-link"
                  onClick={() => {
                    toggleModal();
                    closeMenu();
                  }}
                >
                  Login / SignUp
                </Link>
              )}

              {/* {dropdownOpen && (
                <ul
                  className="z-30  dropdown-menu absolute top-full left-0 bg-[#f9943b] shadow-2xl  rounded-lg "
                  style={{ marginTop: "7px", marginLeft: "-20px" }}
                >
                  <div className=" ">
                    <Link to="/dashboard">
                      <li className=" cursor-pointer flex gap-2 dropdown-item  px-4 py-4 text-sm text-white hover:bg-[#003060] hover:rounded-lg">
                        <span>
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M3 5C3 3.89543 3.89543 3 5 3H9C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H5C3.89543 11 3 10.1046 3 9V5ZM9 5H5V9H9V5Z" />
                            <path d="M3 15C3 13.8954 3.89543 13 5 13H9C10.1046 13 11 13.8954 11 15V19C11 20.1046 10.1046 21 9 21H5C3.89543 21 3 20.1046 3 19V15ZM9 15H5V19H9V15Z" />
                            <path d="M13 5C13 3.89543 13.8954 3 15 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H15C13.8954 11 13 10.1046 13 9V5ZM19 5H15V9H19V5Z" />
                            <path d="M13 15C13 13.8954 13.8954 13 15 13H19C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H15C13.8954 21 13 20.1046 13 19V15ZM19 15H15V19H19V15Z" />
                          </svg>
                        </span>
                        Dashboard
                      </li>
                    </Link>

                    <li
                      className="hover:bg-[#003060] cursor-pointer hover:rounded-lg dropdown-item flex  w-full gap-2 px-4 py-4 text-sm text-white  "
                      onClick={handleLogout}
                    >
                      <span>
                        <svg
                          class="w-5 h-5"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                          <path d="M9 12h12l-3 -3"></path>
                          <path d="M18 15l3 -3"></path>
                        </svg>
                      </span>
                      Logout
                    </li>
                  </div>
                </ul>
              )} */}
            </li>
          </ul>
        </div>
      </header>
      {/* For registration */}
      {/* <RegisterModal isOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} /> */}

      {/* // For login */}
      {/* <LoginModal isOpen2={modalIsOpen2} setModalIsOpen2={setModalIsOpen2} /> */}

      {/* <LoginSinupModal
        isOpen3={modalIsOpen3}
        setModalIsOpen3={setModalIsOpen3}
      /> */}

      {/* {isModalOpen && ( 
         <OtpModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
       )}
         */}
    </>
  );
}
