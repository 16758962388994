import axios from "axios";
// import { UserDetails } from "../types/components";

export const apiCall = axios.create({
  baseURL: "https://studybot.ddnsking.com/",
  headers: {
    "Content-Type": "application/json",
  },
});


apiCall.interceptors.request.use(config => {
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  });