import "./../App.css";
import facebook from "./../images/facebook.png";
import instagram from "./../images/instagram.png";
import twitter from "./../images/twitter.png";
import footerLogo from "./../images/footer-logo.png";
import { Link } from "react-router-dom";
import heroBg from "../images/hero-bg.png";

function Footer() {
  return (
    <footer>
      <img
        src={heroBg}
        alt="hero-bg"
        className="w-full scale-y-[-1] translate-y-[1px]"
      />
      <div className="bg-primary-blue">
        <div className="flex-col flex md:flex-row items-center justify-between p-web">
          <div className="text-white pb-5 ">
            <a href="index.html">
              <span className="font-bold text-4xl">Scholar.AI</span>
            </a>
          </div>
          <div className="gap-[30px] md:gap-0 md:w-[55%] flex items-center justify-between">
            <div className="text-white">
              <h3 className="pb-3">Quick Links</h3>
              <ul className="flex flex-col gap-2">
                <li>
                  <Link to="#">Home</Link>
                </li>
                <li>
                  <Link to="#">About Us</Link>
                </li>
                <li>
                  <Link to="#">Services</Link>
                </li>
                <li>
                  <Link to="#">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="#">Terms & Condition</Link>
                </li>
              </ul>
            </div>
            <div className="text-white">
              <h3 className="pb-3">Quick Links</h3>
              <ul className="flex flex-col gap-2">
                <li>
                  <Link to="#">Home</Link>
                </li>
                <li>
                  <Link to="#">About Us</Link>
                </li>
                <li>
                  <Link to="#">Services</Link>
                </li>
                <li>
                  <Link to="#">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="#">Terms & Condition</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>
          &copy; All Right Reserved By <Link to="#">scholar.ai</Link>
        </p>
      </div>
    </footer>
  );
}
//   <div className="footer-block social">
//     <h3 className="footer-title">We Are on Social</h3>
//     <ul>
//       <li>
//         <Link to="#">
//           <img src={facebook} alt="fb" />
//         </Link>
//       </li>
//       <li>
//         <Link to="#">
//           <img src={instagram} alt="insta" />
//         </Link>
//       </li>
//       <li>
//         <Link to="#">
//           <img src={twitter} alt="twi" />
//         </Link>
//       </li>
//     </ul>
//   </div>

export default Footer;
