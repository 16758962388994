// import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import search from "../../assets/images/search.png";
import arrowDown from "../../assets/images/arrow_down.png";
import bell from "../../assets/images/bell.png";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = (props) => {
  const src =
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  return (
    <header className="shadow-md sticky top-0 z-[1] flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex flex-grow items-center justify-between px-5 py-1 shadow-2  2xl:px-11 w-full">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 18L20 18"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M4 12L20 12"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M4 6L20 6"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div
          className={`${
            pathname.includes("practice") ? "block" : "hidden"
          } pr-5`}
        >
          <NavLink to="/">
            <img src={logo} alt="logo" className="h-6" />
          </NavLink>
        </div>

        <div
          className={`${
            pathname.includes("practice") ? "hidden" : "hidden md:block w-full"
          } `}
        >
          <div className="flex items-center bg-[#E1F2F8] rounded-lg px-2 py-1 w-1/2 ">
            <img
              src={search}
              className="w-5 h-5 text-gray-500 mr-2"
              alt="search"
            />

            <input
              type="text"
              placeholder="Search here....."
              className="outline-none text-sm bg-[#E1F2F8] w-full text-gray-700"
            />
          </div>
        </div>

        <div className="flex items-center gap-3 2xsm:gap-7">
          <div className="flex items-center space-x-4 mr-4">
            {/* <!-- User Area --> */}
            <div
              className="relative"
              onClick={() => {
                if (!pathname.includes("practice")) setMenuOpen(!menuOpen);
              }}
            >
              <div className="text-[#202020] text-sm flex items-center justify-center gap-3 cursor-pointer">
                <img
                  className="inline-block w-8 h-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <div className="text-left">
                  <p>Saurabh</p>
                  <p className="text-xs text-[#636363]">User</p>
                </div>
                <img src={arrowDown} alt="arrow down" className="w-4 h-4" />
              </div>
              <div
                className={`${
                  menuOpen ? "absolute pt-[7px] -right-5" : "hidden"
                }`}
              >
                <div className="text-[#202020] bg-white p-5 rounded-md shadow-lg text-sm w-max">
                  <div className="flex flex-row items-center gap-5 border-b-[1px] pb-4 border-b-[#ececec]">
                    <img
                      className="inline-block w-10 h-10 rounded-full"
                      src={src}
                      alt=""
                    />
                    <div className="text-left">
                      <p>Saurabh</p>
                      <p className="text-xs text-[#636363]">User</p>
                      <p className="text-xs text-[#636363]">
                        saurabh123@gmail.com
                      </p>
                    </div>
                  </div>
                  <div className="border-b-[1px] py-4 border-b-[#ececec] flex flex-col gap-2 text-[#636363]">
                    <div className="cursor-pointer">Free Plan</div>
                    <div className="cursor-pointer">Connect your wallet</div>
                    <div className="cursor-pointer">FAQ</div>
                  </div>
                  <div className="pt-4 flex flex-col gap-2 text-[#0061EB]">
                    <div className="cursor-pointer">Settings</div>
                    <div className="cursor-pointer">Sign out</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            href="/"
            className="flex  justify-center items-center w-8 h-8 rounded-lg text-gray-600 bg-[#E4F9FF] hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300"
          >
            <img src={bell} alt="bell" className="w-4 h-4" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
