import React from 'react'
import TestimonialCard from '../ui/TestimonialCard'
import person from "../../images/person.jpg"
// import Marquee from "react-fast-marquee";
import "./testimonials.css"

// const Blob = ({ rotate, scale, position }) => {
//     return (
//         <div className={` ${rotate} ${scale} ${position} absolute z-10 h-[150px] w-[150px] rounded-full bg-gradient-to-tr from-primary-orange via-primary-orange to-transparent`} />
//     )
// }

export default function Testimonials() {
    const arr = [{
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, dolor sit amet, dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. dolor sit amet,"
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do. do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }, {
        clientImage: { person },
        clientName: "Jane Doe",
        clientRole: "Software Developer",
        rating: 3,
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do."
    }];
    const arr1 = [];
    const arr2 = [];
    const arr3 = [];
    const arr4 = [];
    const totalReviews = arr.length;
    for (let i = 0; i < totalReviews; i++) {
        if (i >= 0 && i < totalReviews / 4) {
            arr1.push(arr[i]);
        } else if (i >= totalReviews / 4 && i <= totalReviews / 2) {
            arr2.push(arr[i]);
        } else if (i > totalReviews / 2 && i < 3 * totalReviews / 4) {
            arr3.push(arr[i]);
        } else {
            arr4.push(arr[i]);
        }
    }
    return (
        <section className='container mx-auto'>
            <div className='bg-white py-[40px] p-web relative overflow-hidden'>
                <div className='bg-white rounded-3xl lg:py-web'>
                    <div className='flex flex-col justify-center items-center pb-10 lg:w-[75%] mx-auto'>
                        <div className='text-5xl md:text-6xl font-semibold pb-5 mb-5 border-b-[10px] border-primary-orange'>
                            WHAT PEOPLE SAY ABOUT US
                        </div>
                        <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div className='w-full gap-4 mx-auto hidden md:flex'>
                        <div className='w-1/4 h-full flex flex-col gap-4'>
                            {arr1.map((item) => (
                                <div className=''>
                                    <TestimonialCard
                                        key={`${item}testimonial1`}
                                        clientImage={person} clientName={item.clientName} clientRole={item.clientRole}
                                        rating={item.rating}
                                        content={item.content} />
                                </div>
                            ))}
                        </div>
                        <div className='w-1/4 h-full flex flex-col gap-4'>
                            {arr2.map((item) => (
                                <div className=''>
                                    <TestimonialCard
                                        key={`${item}testimonial1`}
                                        clientImage={person} clientName={item.clientName} clientRole={item.clientRole}
                                        rating={item.rating}
                                        content={item.content} />
                                </div>
                            ))}
                        </div>
                        <div className='w-1/4 h-full flex flex-col gap-4'>
                            {arr3.map((item) => (
                                <div className=''>
                                    <TestimonialCard
                                        key={`${item}testimonial1`}
                                        clientImage={person} clientName={item.clientName} clientRole={item.clientRole}
                                        rating={item.rating}
                                        content={item.content} />
                                </div>
                            ))}
                        </div>
                        <div className='w-1/4 h-full flex flex-col gap-4'>
                            {arr4.map((item) => (
                                <div className=''>
                                    <TestimonialCard
                                        key={`${item}testimonial1`}
                                        clientImage={person} clientName={item.clientName} clientRole={item.clientRole}
                                        rating={item.rating}
                                        content={item.content} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='md:hidden h-full flex flex-col gap-4'>
                        {arr1.map((item) => (
                            <div className=''>
                                <TestimonialCard
                                    key={`${item}testimonial1`}
                                    clientImage={person} clientName={item.clientName} clientRole={item.clientRole}
                                    rating={item.rating}
                                    content={item.content} />
                            </div>
                        ))}
                    </div>
                </div>
                {/* <Blob rotate={"rotate-90"} scale="scale-[0.9]" position={"bottom-[40px] right-0 hidden lg:block"} />
                <Blob rotate={"rotate-180"} scale="scale-[0.9]" position={"top-[10px] right-[100px] hidden xl:block"} /> */}
            </div>
        </section >
    )
}
