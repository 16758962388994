// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#aboutDots {
  position: absolute;
  bottom: -40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  list-style-type: none;
}
#aboutDots li {
  margin: 0 0.25rem;
}
#aboutDots li button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  margin: auto;
  border: none;
  border-radius: 100%;
  background-color: #999999;

  text-indent: -9999px;
}

#aboutDots li.slick-active button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  background-color: #F9943B;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/aboutus.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,cAAc;EACd,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;;EAEzB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,UAAU;EACV,yBAAgD;AAClD","sourcesContent":["#aboutDots {\n  position: absolute;\n  bottom: -40px;\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  margin: 0;\n  list-style-type: none;\n}\n#aboutDots li {\n  margin: 0 0.25rem;\n}\n#aboutDots li button {\n  display: block;\n  width: 0.75rem;\n  height: 0.75rem;\n  margin: auto;\n  border: none;\n  border-radius: 100%;\n  background-color: #999999;\n\n  text-indent: -9999px;\n}\n\n#aboutDots li.slick-active button {\n  display: block;\n  width: 1rem;\n  height: 1rem;\n  padding: 0;\n  background-color: theme(\"colors.primary-orange\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
