import "./../App.css";
// import BSF from "./../images/BSF_Insignia.png";
// import enroll from "./../images/business-strategy.gif";
// import Slider from "./../Slider";

import RegisterModal from "../components/Modal.jsx";
import LoginModal from "../components/LoginModal.jsx";
import OtpModal from "../components/Otpmodal.jsx";
import { toast, ToastContainer } from "react-toastify";

import Header from "./../page/Header";
import Footer from "./../page/Footer";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HeroSection from "../components/home/HeroSection";
import Features from "../components/home/Features";
// import AboutUs from "../components/home/AboutUs";
import Counter from "../components/home/Counter";
import Courses from "../components/home/Courses";
import ClientReviews from "../components/home/ClientReviews";
// import Demo from "../components/home/Demo";
// import Testimonials from "../components/home/Testimonials";
// import ContactUs from "../components/home/ContactUs";
import WhyStudyBot from "../components/home/WhyStudyBot.jsx";
import Faqs from "../components/home/Faqs.jsx";

function Home() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  // const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false); // Initialize with false
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [exams, setExams] = useState([]);

  // useEffect(() => {
  //   // const Apiurl = process.env.REACT_APP_API_URL;
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         // `${process.env.REACT_APP_API_URL}/api/exam`
  //         // `${process.env.API_BASE_URL}/api/users`
  //         `http://192.168.1.10:8000/api/exams`
  //       );

  //       console.log(response?.data?.data, "getData");
  //       setExams(response?.data?.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  // console.log(exams);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeMenu = () => {
    setOpen(false);
  };
  const [items, setItems] = useState([]);
  const getID = localStorage.getItem("users");

  useEffect(() => {
    setItems(JSON.parse(getID));
  }, [getID]);

  const handleLogout = () => {
    localStorage.removeItem("users");
    toast.success("You are successfully logged out", {
      duration: 1000,
      position: "bottom-right",
    });
    setItems(null);
    setDropdownOpen(false);
  };

  return (
    <div className="App">
      <ToastContainer />
      <Header
        getID={getID}
        items={items}
        toggleDropdown={toggleDropdown}
        toggleModal={toggleModal}
        closeMenu={closeMenu}
      />
      <HeroSection toggleModal={toggleModal} closeMenu={closeMenu} />
      <Features />
      <Counter />
      <WhyStudyBot />
      {/* <AboutUs /> */}
      <Courses />
      {/* <Demo /> */}
      <ClientReviews />
      <Faqs />
      {/* <Testimonials /> */}
      {/* <ContactUs /> */}
      {/* <section className="about-us">
        <div className="container">
          <div className="about-inner">
            <div className="about-left">
              <h2 className="about-title ">
                Evaluate Your Exam Prepration with Our AI Analysis Tool
              </h2>
            </div>
            <div className="about-right">
              <a className="btn btn-white">Apply Now</a>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="enrollment enrollmentMobile">
        <div className="container">
          <div className="enrollment-inner">
            <div className="enroll-left">
              <img src={enroll} alt="Left Image" />
            </div>
            <div className="enroll-right">
              <h2 className="enroll-title">
                Enroll with <span>SCHOLAR.AI</span>
              </h2>
              <h3 className="enroll-sub-title">Features </h3>
              <ul>
                <li>
                  <p className="text-justify">
                    1. Get feedback on your subjective answers from AI:
                    Scholar.AI uses AI to analyze your subjective answers and
                    provide feedback on your factual accuracy, language
                    consistency, and other important parameters.
                  </p>
                </li>
                <li>
                  <p className="text-justify">
                    2. Identify areas for improvement and get personalized
                    recommendations: Once you have received feedback on your
                    answers, Scholar.AI will identify areas for improvement and
                    provide you with personalized recommendations. This will
                    help you to focus your preparation and improve your
                    subjective answers
                  </p>
                </li>
                <li>
                  <p className="text-justify">
                    3. Prepare for competitive exams with confidence: Scholar.AI
                    is a valuable tool for students who are preparing for
                    competitive exams that require subjective answers.
                  </p>
                </li>
              </ul>
              <p className="text-justify">
                Scholar.AI is an AI-powered subjective answer analysis tool that
                can help students to improve their subjective answers and
                prepare for competitive exams better. Scholar.AI provides
                students with feedback on their factual accuracy, language
                consistency, and other important parameters. It also identifies
                areas for improvement and provides personalized recommendations.
              </p>
            </div>
          </div>
          <div className="scholar">
            <a href="#" className="btn btn-primary">
              Try Now
            </a>
          </div>
        </div>
      </section> */}
      {/* <section className="cta">
        <div className="container">
          <h2 className="motivation-title">
            AI is going to change the way we learn and teach
          </h2>
          <span className="author"> - Andrew Ng</span>
        </div>
      </section> */}
      {/* <section className="step">
        <div className="container">
          <h3 className="section-title">
            Popular <span>Exam</span>
          </h3>
          <div className="step-wrapper">
            {exams &&
              exams.map((exam) => {
                const imageUrl = `http://192.168.1.10:8000/${exam.image}`;
                const id = `${exam.id}`;

                return (
                  <div className="step-box" key={exam.id}>
                    <Link to={`/exam/${id}`} style={{ textDecoration: "none" }}>
                      <h2 className="step-box-title">
                        <img src={imageUrl} alt="Exam Image" />
                      </h2>
                      <h3 className="step-sub-title">{exam.exam_name}</h3>
                      <p className="step-content">{exam.tests} Test</p>
                    </Link>
                  </div>
                );
              })}
          </div>
          <div className="more">
            <a href="#" className="btn btn-primary">
              Explore All Exam
            </a>
          </div>
        </div>
      </section> */}
      {/* <section className="pricing">
        <div className="container">
          <h2 className="section-title">
            Why <span>SCHOLAR.AI?</span>
          </h2>
          <div className="why">
            <div className="why-left">
              <p className="text-justify">
                Scholar.AI is an AI-powered subjective answer analysis tool that
                can help students to improve their subjective answers and
                prepare for competitive exams better. Scholar.AI provides
                students with feedback on their factual accuracy, language
                consistency, and other important parameters. It also identifies
                areas for improvement and provides personalized recommendations.
              </p>
              <a href="#" className="btn btn-primary">
                Get Started
              </a>
            </div>
            <div className="why-right">
              <div className="subscription-wrapper">
                <div className="subscription-box">
                  <h3 className="package-title">Basic</h3>
                  <h4 className="package-price">$300</h4>
                  <ul>
                    <li>
                      <span></span> Weekly Test
                    </li>
                    <li>
                      <span></span> Monthly Revision
                    </li>
                    <li>
                      <span></span> 3 Months Course
                    </li>
                    <li>
                      <span></span> Working Speed Seminar
                    </li>
                    <li>
                      <span></span> Live Support
                    </li>
                  </ul>
                  <div className="demo">
                    <a href="#" className="btn btn-secondary">
                      Take a Demo
                    </a>
                  </div>
                </div>

                <div className="subscription-box">
                  <h3 className="package-title">Advance</h3>
                  <h4 className="package-price">$500</h4>
                  <ul>
                    <li>
                      <span></span> Weekly Test
                    </li>
                    <li>
                      <span></span> Monthly Revision
                    </li>
                    <li>
                      <span></span> 3 Months Course
                    </li>
                    <li>
                      <span></span> Working Speed Seminar
                    </li>
                    <li>
                      <span></span> Live Support
                    </li>
                  </ul>
                  <div className="demo">
                    <a href="#" className="btn btn-secondary">
                      Take a Demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="newsletter">
        <div className="Subscribe to With Us">
          <h2 className="newsletter-title">Subscribe to Newsletter</h2>
          <form>
            <div className="subscription">
              <input type="email" placeholder="Enter Your Email" />
              <button type="submit" className="btn btn-secondary">
                Notify Me
              </button>
            </div>
          </form>
        </div>
      </section> */}
      {/* <section className="happy-clinet">
        <h2 className="section-title">
          Our Happy <span>Students</span>
        </h2>
        <div className="container">
          <Slider />
        </div>
      </section> */}
      <Footer />
      For registration
      <RegisterModal isOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      {/* // For login */}
      <LoginModal isOpen2={modalIsOpen2} setModalIsOpen2={setModalIsOpen2} />
      {/* <LoginSinupModal
        isOpen3={modalIsOpen3}
        setModalIsOpen3={setModalIsOpen3}
      /> */}
      {isModalOpen && (
        <OtpModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      )}
    </div>
  );
}

export default Home;
