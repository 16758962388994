import React, { createContext } from 'react'
import course from "../../images/courses.jpg"
import "./aboutus.css"
import Button from '../ui/Button'

export const AboutContext = createContext();

export default function Features() {
    return (
        <section className='container mx-auto p-web text-center'>
            <div className='w-4/5 mx-auto'>
                <div className='text-5xl md:text-6xl font-semibold pb-5'>
                    How Study Bot AI works
                </div>
                {/* <p>
                    Scholar.AI is an AI-powered subjective answer analysis tool that can help students to improve their subjective answers and prepare for competitive exams better. Scholar.AI provides students with feedback on their factual accuracy, language consistency, and other important parameters.
                </p> */}
            </div>
            <div className='flex flex-col md:flex-row mx-auto gap-4 text-left'>
                <div className='bg-white rounded-md shadow-lg md:w-1/4 p-5 hover:scale-[1.03] cursor-pointer transition-transform duration-100'>
                    <img src={course} alt="about1" className='rounded-md' />
                    <div className='text-xl font-bold pt-3 pb-1'>AI-Powered Answer Analysis</div>
                    Analyze subjective answers and get detailed feedback on clarity, relevance, and structure based on exam criteria.
                </div>
                <div className='bg-white rounded-md shadow-lg md:w-1/4 p-5 hover:scale-[1.03] cursor-pointer transition-transform duration-100'>
                    <img src={course} alt="about1" className='rounded-md' />
                    <div className='text-xl font-bold pt-3 pb-1'>Automated Performance Summary</div>
                    Receive automated performance summary with insights into areas of improvement
                </div>
                <div className='bg-white rounded-md shadow-lg md:w-1/4 p-5 hover:scale-[1.03] cursor-pointer transition-transform duration-100'>
                    <img src={course} alt="about1" className='rounded-md' />
                    <div className='text-xl font-bold pt-3 pb-1'>AI Query Answering</div>
                    Input any question, and Scholar AI provides accurate and detailed answers.
                </div>
                <div className='bg-white rounded-md shadow-lg md:w-1/4 p-5 hover:scale-[1.03] cursor-pointer transition-transform duration-100'>
                    <img src={course} alt="about1" className='rounded-md' />
                    <div className='text-xl font-bold pt-3 pb-1'>Interactive Practice Tests</div>
                    Practice tests and simulations with instant feedback to prepare you for exam day.
                </div>

            </div>
            <div className='pt-10'>
                <Button variant={"orange"} text="Discover all features" />
            </div>

        </section>
    )
}
