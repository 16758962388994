import React, { createContext, useState } from 'react'
import about1 from "../../images/about1.jpg"
import about2 from "../../images/about2.jpg"
import about3 from "../../images/about3.jpg"
import AboutCard from '../ui/AboutCard'
import Slider from 'react-slick'
import course from "../../images/courses.jpg"
import "./aboutus.css"
import Button from '../ui/Button'

export const AboutContext = createContext();

export default function WhyStudyBot() {
    const [current, setCurrent] = useState(-1);
    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        autoplay: true,
        arrows: false,
        appendDots: (dots) => (
            <ul id='aboutDots' className=''>{dots}</ul>
        )
    };
    return (
        <section className='container mx-auto p-web text-center'>
            <div className='w-4/5 mx-auto'>
                <div className='text-5xl md:text-6xl font-semibold pb-5'>
                    Why Study Bot AI?
                </div>
                {/* <p>
                    Scholar.AI is an AI-powered subjective answer analysis tool that can help students to improve their subjective answers and prepare for competitive exams better. Scholar.AI provides students with feedback on their factual accuracy, language consistency, and other important parameters.
                </p> */}
            </div>
            <div className='flex flex-col mx-auto'>
                <div className='flex flex-col md:flex-row md:justify-center md:items-center'>
                    <div className='my-5 md:m-5 md:w-1/2 md:p-7'>
                        <div className='text-3xl font-bold pb-3'>AI Powered Feedback</div>
                        Real time analysis and instant suggestions to improve your answers
                    </div>
                    <div className='md:w-1/2 md:p-7'>
                        <img src={course} alt="about1" className='w-full' />
                    </div>
                </div>
                <div className='hidden md:flex flex-col md:flex-row md:justify-center md:items-center'>
                    <div className=' sha dow-lg md:w-1/2 md:p-7'>
                        <img src={course} alt="about1" className='w-full' />
                    </div>
                    <div className=' my-5 md:m-5 md:w-1/2 md:p-7'>
                        <div className='text-3xl font-bold pb-3'>Adaptive learning</div>
                        Custom quizzes and practice tools tailored to your learning pace
                    </div>
                </div>
                <div className='flex md:hidden flex-col md:flex-row md:justify-center md:items-center'>
                    <div className='my-5 md:m-5 md:w-1/2 md:p-7'>
                        <div className='text-3xl font-bold pb-3'>Adaptive learning</div>
                        Custom quizzes and practice tools tailored to your learning pace
                    </div>
                    <div className=' sha dow-lg md:w-1/2 md:p-7'>
                        <img src={course} alt="about1" className='w-full' />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row md:justify-center md:items-center'>
                    <div className='my-5 md:m-5 md:w-1/2 md:p-7'>
                        <div className='text-3xl font-bold pb-3'>Performance tracking</div>
                        Detailed reports of your strengths, weaknesses and improvement areas.
                    </div>
                    <div className='bg-white rounded-md sha dow-lg md:w-1/2 md:p-7'>
                        <img src={course} alt="about1" className='w-full' />
                    </div>
                </div>
            </div>
            <div className='pt-4'>
                <Button variant={"orange"} text="Discover all features" />
            </div>

        </section>
    )
}
