import React from 'react'
// import heroImage from "../../images/hero_image.png"
import heroBg from "../../images/hero-bg.png";
import Button from '../ui/Button'
import scrollToSection from '../reusableFunctions/scrollToSection';

export default function HeroSection({ toggleModal, closeMenu }) {
    return (
        <section className="mt-[55px]">
            <div className='hidden md:block w-full h-[30px] bg-primary-blue ' />
            <div className='bg-primary-blue'>
                <div className='container mx-auto p-web lg:py-[60px] flex-col flex md:flex-row gap-5'>
                    {/* <div className='md:w-1/2'>
                        <img src={heroImage} alt="heroImage" />
                    </div> */}
                    <div className='w-full text-white text-center font-bold text-4xl md:leading-snug lg:text-[3.75rem]'>
                        Unlock the Power of AI for Smarter Learning and Exam Preparation
                        <br />
                        <p className='text-lg pt-4 font-medium'>"Transform the way you learn with AI-driven insights, feedback, and personalized support for your competitive and academic exams"</p>
                        {/* <br /> */}
                        <div className='flex justify-center items-center gap-4 pt-10'>
                            <Button variant={"orange"} text="Get started" onClick={() => {
                                toggleModal();
                                closeMenu();
                            }} />
                            <Button variant={"orange"} text="How it works" onClick={() => scrollToSection("features")} />
                        </div>
                    </div>
                </div>
            </div>
            <img src={heroBg} alt="hero-bg" className='w-full' />

            {/* <p>
                Scholar.AI - AI-powered Subjective Answer Analysis for
                Students
                Improve your subjective answers and prepare for competitive
                exams better.
            </p> */}
        </section >
    )
}
