import React from 'react'

const Star = ({ filled }) => (
    <span style={{ color: filled ? '#F9943B' : '#ccc', fontSize: '20px', marginRight: '4px' }}>
        ★
    </span>
);

const StarRating = ({ rating }) => {
    const totalStars = 5;
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    return (
        <div>
            {[...Array(totalStars)].map((_, index) => {
                if (index < filledStars) {
                    return <Star key={`star-5-${index}`} filled={true} />;
                } else if (index === filledStars && hasHalfStar) {
                    return <Star key={`star-6-${index}`} filled={false} style={{ color: '#F9943B' }} />;
                } else {
                    return <Star key={`star-7-${index}`} filled={false} />;
                }
            })}
        </div>
    );
};

// const Quotation = ({ rotate }) => {
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={16} width={16} className={`${rotate}`}><path d="M0 216C0 149.7 53.7 96 120 96l8 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-8 0c-30.9 0-56 25.1-56 56l0 8 64 0c35.3 0 64 28.7 64 64l0 64c0 35.3-28.7 64-64 64l-64 0c-35.3 0-64-28.7-64-64l0-32 0-32 0-72zm256 0c0-66.3 53.7-120 120-120l8 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-8 0c-30.9 0-56 25.1-56 56l0 8 64 0c35.3 0 64 28.7 64 64l0 64c0 35.3-28.7 64-64 64l-64 0c-35.3 0-64-28.7-64-64l0-32 0-32 0-72z" /></svg>
//     )
// }

export default function TestimonialCard({ clientImage, clientName, clientRole, content, rating }) {
    return (
        <div className='bg-white w-full rounded-xl shadow-courses h-auto p-3'>
            <div className='p-3 border-[2px] rounded-xl border-primary-orange'>
                <div className='pt-0 flex items-center justify-start gap-2'>
                    <img src={clientImage} alt="profile" className='rounded-full h-12 w-12 border-[2px] border-primary-orange' />
                    <div className='text-xs'>
                        <div className='font-semibold'>{clientName}</div>
                        <div className='text-xs'>{clientRole}</div>
                    </div>
                </div>
                <div className='text-left'>
                    <StarRating rating={rating} />
                </div>
                {/* <Quotation /> */}
                <div className='text-xs'>{content}</div>
                {/* <div className='flex items-center justify-end'>
                    <Quotation rotate={"rotate-180"} />
                </div> */}
                <div className='text-xs text-gray-500 pt-4'>
                    Jan 4, 2024
                </div>
            </div>
        </div>
    )
}
