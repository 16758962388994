/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Lottie from "lottie-react"; // Import Lottie component from lottie-react
import animationData from "../otp.json";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import Google from "../images/google.svg";
import loginImage from "../images/login2.png";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiCall } from "../api/login";
// import { UserContext } from "../context/userContext";

export default function OtpModal({ isOpen, setIsOpen }) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showLoginSignup, setShowLoginSignup] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        // await
      } catch (error) {
        console.error("Error during submission:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const validate = () => {
    let tempErrors = {};
    if (
      !formData.email_or_phone ||
      !/\S+@\S+\.\S+/.test(formData.email_or_phone)
    )
      tempErrors.email_or_phone = "Valid email is required";
    if (!formData.password) {
      tempErrors.password = "Password is required";
    }
    if (!formData.re_password) {
      tempErrors.re_password = "Confirm Password is required";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    re_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const signupData = new FormData();
    signupData.append("email", formData.email);
    signupData.append("password", formData.password);
    signupData.append("re_password", formData.re_password);
    try {
      const response = await apiCall.post("signup", signupData);
      if (response.data.message === "Account created") {
        toast.success("Account created successfully!");
        setFormData({
          email: "",
          password: "",
          re_password: "",
        });
      } else if (response.data.message === "Email already exists") {
        toast.error(response.data.message);
      } else {
        toast.error("An error occurred while creating account.");
      }
    } catch (error) {
      console.error("Request failed", error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <Transition show={open}>
        <Dialog className="relative " onClose={() => setIsOpen(false)}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000bf]  z-[1000] transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-[10001] w-screen overflow-y-auto bgModal">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className=" relative transform overflow-hidden rounded-[1rem] bg-white text-left shadow-xl transition-all sm:my-8  sm:w-full sm:max-w-3xl ">
                  <button
                    onClick={closeModal}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-900"
                    aria-label="Close"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                  <div className="flex flex-col sm:flex-row ">
                    {/* left section */}
                    {showLoginSignup ? (
                      <div className="left-panel w-full md:w-1/2  bg-white py-10 sm:py-0 sm:p-10 flex flex-col justify-center">
                        <div className="">
                          <h2 className="mb-4 font-bold text-2xl">
                            Sign up to your account
                          </h2>
                          <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                              <div className="row">
                                <div className="col-sm-6 col-md-12">
                                  <div className="mb-4">
                                    <input
                                      type="email"
                                      className="flex-1 border rounded-lg  p-2 bg-transparent focus:outline-none "
                                      id="email"
                                      name="email"
                                      value={formData.email}
                                      onChange={handleChange}
                                      placeholder="Email"
                                    />
                                    {errors.email && (
                                      <div className="error-message">
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-12 mb-2">
                                    <div className="mb-4">
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="flex-1  border rounded-lg p-2 bg-transparent focus:outline-none "
                                          id="password"
                                          name="password"
                                          value={formData.password}
                                          onChange={handleChange}
                                          placeholder="Password"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-md-12 mb-2">
                                    <div className="mb-4">
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="flex-1  border rounded-lg p-2 bg-transparent focus:outline-none "
                                          id="re_password"
                                          name="re_password"
                                          value={formData.re_password}
                                          onChange={handleChange}
                                          placeholder="Password"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {loading ? (
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-full fw-semibold py-2 mt-2"
                                      style={{
                                        background: "#f4812d",
                                        border: 0,
                                      }}
                                      disabled
                                    >
                                      Sign in
                                      <div
                                        className="spinner-border spinner-border-sm ms-2"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-full fw-semibold py-2 mt-2"
                                      style={{
                                        background: "#f4812d",
                                        border: 0,
                                      }}
                                      onClick={handleClick}
                                    >
                                      Sign in
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                          <div
                            className="mt-3 mb-3 text-center"
                            style={{ fontSize: "14px" }}
                          >
                            Already have an account
                            <Link
                              // to="/sign-up"
                              className="ms-2"
                              style={{ color: "#f4812d" }}
                              onClick={() =>
                                setShowLoginSignup(!showLoginSignup)
                              }
                            >
                              Login
                            </Link>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button className="btn border rounded-lg flex items-center gap-3 text-dark px-3 py-2">
                                <img
                                  className="img-fluid"
                                  style={{ width: "24px", height: "24px" }}
                                  src="https://www.svgrepo.com/show/475656/google-color.svg"
                                  loading="lazy"
                                  alt="google logo"
                                />
                                <span>Login with Google</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="left-panel w-full md:w-1/2  bg-white py-10 sm:py-0 sm:p-10 flex flex-col justify-center">
                        <div className="">
                          <h2 className="mb-4 font-bold text-2xl">
                            Login to your account
                          </h2>
                          <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                              <div className="">
                                <div className="">
                                  <div className="mb-4">
                                    <input
                                      type="email"
                                      className="flex-1 border rounded-lg  p-2 bg-transparent focus:outline-none "
                                      id="email"
                                      name="email"
                                      value={formData.email}
                                      onChange={handleChange}
                                      placeholder="Email"
                                    />

                                    {errors.email_or_phone && (
                                      <div className="error-message text-red-500">
                                        {errors.email_or_phone}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-12 mb-2">
                                    <div className="mb-4">
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="flex-1  border rounded-lg p-2 bg-transparent focus:outline-none "
                                          id="password"
                                          name="password"
                                          value={formData.password}
                                          onChange={handleChange}
                                          placeholder="Password"
                                        />
                                      </div>
                                      {errors.password && (
                                        <div className="error-message text-red-500">
                                          {errors.password}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="text-end">
                                    <Link
                                      // to="/forgot-password"
                                      className="ms-2"
                                      style={{
                                        color: "#f4812d",
                                        textDecoration: "none",
                                      }}
                                    >
                                      Forgot Password
                                    </Link>
                                  </div>
                                  {loading ? (
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-full fw-semibold py-2 mt-2"
                                      style={{
                                        background: "#f4812d",
                                        border: 0,
                                      }}
                                      disabled
                                    >
                                      Log in
                                      <div
                                        className="spinner-border spinner-border-sm ms-2"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-full fw-semibold py-2 mt-2"
                                      style={{
                                        background: "#f4812d",
                                        border: 0,
                                      }}
                                    >
                                      Log in
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                          <div
                            className="mt-3 mb-3 text-center"
                            style={{ fontSize: "14px" }}
                          >
                            Need an account?
                            <Link
                              // to="/sign-up"
                              className="ms-2"
                              style={{ color: "#f4812d" }}
                              onClick={() => {
                                setShowLoginSignup(!showLoginSignup);
                              }}
                            >
                              Sign Up
                            </Link>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button className="btn border rounded-lg flex items-center gap-3 text-dark px-3 py-2">
                                <img
                                  className="img-fluid"
                                  style={{ width: "24px", height: "24px" }}
                                  src="https://www.svgrepo.com/show/475656/google-color.svg"
                                  loading="lazy"
                                  alt="google logo"
                                />
                                <span>Login with Google</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* right section */}
                    <div className="right-panel w-full md:w-1/2 bg-white   bg-gradient-custom ">
                      <div
                        className="  bg-[#e0e7ff] justify-center items-center flex-1 text-center   lg:flex rounded-tr-[10px] rounded-br-[10px]"
                        style={{ padding: "100px 0px" }}
                      >
                        <img
                          src={loginImage}
                          alt="login"
                          className="lg:w-full lg:h-auto"
                        />
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
