 

const DashboardHome = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      {/* <div>
        <button onClick={openModal}>Open Modal</button>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="flex justify-between py-5 items-center">
            <h2>Questions : 1</h2>
            <button className="bg-[#0AA6D7] text-white px-4 py-1 rounded-lg">
              Submit
            </button>
          </div>
          <p>
            lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys stand?
          </p>
        </Modal>
      </div> */}

    <div>Dashboard Home</div>
    </div>
  );
};

export default DashboardHome;
