// import starIcon from "../../assets/images/starticon.png";
import starIcon from "../../assets/images/starticon.svg";
import upArrow from "../../assets/images/upArrow.png";
import Billing from "../../assets/images/Billing.png";
import SolidCheck from "../../assets/images/solid_check.png";
import PaymentHistory from "../../assets/images/payment_history.png";
import Candidate from "../../assets/images/candidate.png";
import { SubscriptionData } from "../../data/data";
import { useState } from "react";

const Subscription = () => {
  const [activeTab, setActiveTab] = useState("billing");

  return (
    <>
      <div>
        <div className="text-sm font-medium text-center text-[#637381] mb-5 ">
          <ul className="flex flex-wrap -mb-px gap-5">
            <li
              className={`mr-2 flex items-center border-b-2 ${
                activeTab === "billing"
                  ? "border-[#212B36] border-b-2"
                  : "border-transparent"
              } hover:text-gray-600 hover:border-gray-300`}
              onClick={() => setActiveTab("billing")}
            >
              <span>
                <img src={Billing} alt="Billing" className="w-5 h-5" />
              </span>
              <a
                href="#"
                className={`inline-block p-4 rounded-t-lg ${
                  activeTab === "billing" ? "text-[#212B36]" : ""
                }`}
              >
                Billing
              </a>
            </li>
            <li
              className={`mr-2 flex items-center border-b-2 ${
                activeTab === "paymentHistory"
                  ? "border-[#212B36]"
                  : "border-transparent"
              } hover:text-gray-600 hover:border-gray-300`}
              onClick={() => setActiveTab("paymentHistory")}
            >
              <span>
                <img
                  src={PaymentHistory}
                  alt="PaymentHistory"
                  className="w-5 h-5"
                />
              </span>
              <a
                href="#"
                className={`inline-block p-4 rounded-t-lg ${
                  activeTab === "paymentHistory" ? "text-[#212B36]" : ""
                }`}
                aria-current="page"
              >
                Payment History
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Content based on active tab */}
      {activeTab === "paymentHistory" ? (
        <section className="bg-white py-12 rounded-2xl">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl font-extrabold text-black">
              Payment History
            </h2>
            {/* Add your payment history content here */}
          </div>
        </section>
      ) : (
        <>
          <section className="bg-white py-12 rounded-2xl">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between items-center mb-8">
                <h2 className="text-2xl font-extrabold text-black">Plans</h2>
                <h2 className="flex items-center gap-3 cursor-pointer font-extrabold text-[#0061EB]">
                  Compare Subscription Plans
                  <span>
                    <img src={upArrow} alt="upArrow" />
                  </span>
                </h2>
              </div>

              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                {SubscriptionData.map((plan, index) => (
                  <div
                    key={`subscriptionid-${index}`}
                    className={`h-[420px] bg-white rounded-lg p-6 transform hover:border-[#001921] hover:scale-105 transition duration-300 border border-gray-150 ${
                      plan.current ? "relative" : ""
                    }`}
                  >
                    {plan.current && (
                      <p className="flex items-center gap-2 absolute right-2 py-1.5 px-2 bg-[#001921] text-white rounded-lg text-xs font-semibold uppercase tracking-wide transform -translate-y-1/2">
                        <span>
                          <img
                            src={starIcon}
                            alt="current"
                            className="text-white"
                          />
                        </span>
                        Current
                      </p>
                    )}
                    <div className="mb-8 mt-5">
                      <h3 className="text-2xl font-semibold text-black">
                        {plan.name}
                      </h3>
                      <p className="text-gray-400">{plan.description}</p>
                    </div>
                    <div className="mb-8">
                      <span className="text-4xl font-semibold text-black">
                        {plan.price}
                      </span>
                      <span className="text-xl font-medium text-gray-400">
                        {plan.frequency}
                      </span>
                    </div>
                    <p className="text-black uppercase font-medium mb-4">
                      What's included
                    </p>
                    <ul className="mb-8 space-y-4 text-gray-400">
                      {plan.features.map((feature, idx) => (
                        <li
                          key={`subscription-2-${idx}`}
                          className="flex items-center gap-2"
                        >
                          <img
                            src={SolidCheck}
                            alt="SolidCheck"
                            className="h-4 w-4"
                          />
                          <span className="text-black">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="mt-10 flex   md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
                <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-[60%]   space-y-6">
                  <div className="flex justify-between items-center w-full">
                    <h3 className="text-xl  leading-5 text-[#637381]">Plan</h3>
                    <p className="text-[#212B36] font-medium">Premium</p>
                  </div>
                  <div className="flex justify-center items-center w-full space-y-4 flex-col   pb-4">
                    <div className="flex justify-between w-full items-center">
                      <p className="text-base leading-4 text-[#637381]">
                        Scheme
                      </p>
                      <p className="text-base leading-4 text-gray-600">
                        <div className="flex justify-center">
                          <nav className="flex overflow-x-auto items-center p-1 space-x-1 rtl:space-x-reverse text-sm text-gray-600  rounded-xl border">
                            <button
                              role="tab"
                              type="button"
                              className="flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none focus:ring-2 focus:[#0061EB29]  focus:ring-inset   shadow    bg-[#001921] text-white"
                              aria-selected=""
                            >
                              Monthly
                            </button>
                            <span className="bg-[#F4F6F8] px-3 py-1 rounded-md">
                              -15% off
                            </span>
                            <button
                              role="tab"
                              type="button"
                              className="flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none focus:ring-2 focus:[#0061EB29] focus:ring-inset hover:text-[#637381]   dark:text-gray-400 dark:hover:text-[#0037A9] dark:focus:text-gray-400"
                            >
                              Yearly
                            </button>
                          </nav>
                        </div>
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="text-base leading-4 text-[#637381]">
                        Billing Name
                      </p>
                      <p className="text-base leading-4 text-[#212B36]">
                        <select className="block  w-sm text-sm font-medium transition duration-75   rounded-lg  h-9  bg-white">
                          <option value="week">Lucian Obrien</option>
                          <option value="month">Max</option>
                          <option value="year">John Doe</option>
                        </select>
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full pb-2">
                      <p className="text-base leading-4 text-[#637381]">
                        Billing Address
                      </p>
                      <p className="text-base leading-4 text-gray-600">
                        1147 Rohan Drive Suite 819 - Burlington, VT / 82021
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="text-base leading-4 text-[#637381]">
                        Billing Phone number
                      </p>
                      <p className="text-base leading-4 text-gray-600">
                        904-966-2836
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="text-base leading-4 text-[#637381]">
                        Payment Method
                      </p>
                      <p className="text-base leading-4 text-[#212B36]">
                        <select className="block w-sm text-sm font-medium transition duration-75   rounded-lg  h-9  bg-white">
                          <option value="week">Visa -1234</option>
                          <option value="month">Debit-9034</option>
                          <option value="year">Credit -5862 </option>
                        </select>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-4 justify-end mt-10">
                <a
                  className="px-4 py-2 min-w-[120px] text-center text-[#212B36] border border-[#001921]  rounded hover:bg-[#001921] hover:text-white hover:border-[#001921]  focus:outline-none focus:ring font-semibold"
                  href="#"
                >
                  Cancel Plan
                </a>
                <a
                  className="px-4 py-2 min-w-[120px] text-center text-white bg-[#001921]  border border-[#001921] rounded  hover:bg-transparent hover:text-[#212B36] focus:outline-none focus:ring font-semibold"
                  href="#"
                >
                  Upgrade Plan
                </a>
              </div>
            </div>
          </section>

          <section
            className="bg-red-500 mt-10 py-12 rounded-2xl"
            style={{
              background:
                "linear-gradient(112.47deg, #001921 13.81%, #FFFFFF 76.65%)",
            }}
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white">
              <div className="flex justify-between mb-3">
                <p className="font-semibold text-xl">Enterprise</p>
                <h2 className="flex items-center gap-3 cursor-pointer font-extrabold text-[#0061EB]">
                  <span>
                    <img src={Candidate} alt="Candidate" />
                  </span>
                  Upgrade to Enterprise
                </h2>
              </div>
              <p>
                Keep your organization focused as you scale. Starting at 100
                members.
              </p>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Subscription;
