/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import CourseCard from '../ui/CourseCard'
import course from "../../images/courses.jpg"
import person from "../../images/person.jpg"
import "./courses.css";
import { apiCall } from '../../api/login';
import { UserContext } from '../../context/userContext';
const Blob = ({ rotate, scale, position }) => {
    return (
        <div className={` ${rotate} ${scale} ${position} absolute z-10 h-[150px] w-[150px] rounded-full bg-gradient-to-tr from-primary-orange via-primary-orange to-transparent`} />
    )
}

const Arrow = ({ rotate, scale, position }) => (
    <div className={`${rotate} ${scale} ${position}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height={20} width={20}><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" fill='#F9943B' /></svg>
    </div>
)

export default function Courses() {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const { user } = useContext(UserContext);
    const slider = useRef(null);
    const [data, setData] = useState([]);
    const getData = async () => {
        try {
            // setLoading({
            //     status: true,
            //     text: "Fetching Exams ...",
            // });

            // apiCall.post("get_questions", JSON.stringify({ examId: 12345 }))

            const response = await (await apiCall.get("get_exam_sets")).data
            // console.log("reponse questions: ", response)
            const dummyArr = response.map((item) => ({
                courseImage: { course },
                authorImage: { person },
                authorName: "Jane Doe",
                authorRole: "Software Developer",
                courseDescription: item.set_name,
                rating: 3,
                timing: item.time,
                set_name: item.set_name,
                task_type: item.task_type,
                exam_id: item.exam_id
            }));
            // console.log("Dummy array: ", dummyArr)
            setData(dummyArr);
            // console.log("reponse: ", response);
        } catch (error) {
            console.log("error fetching question: ", error);
        }
    }
    useEffect(() => {
        getData();
    }, [])

    const maxDots = 8;
    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 2,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 768, // Adjust the width as needed
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024, // Optionally adjust for tablet
                settings: {
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1368, // Optionally adjust for tablet
                settings: {
                    dots: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
        ],
        customPaging: (i) => {
            try {
                if (data.length > 0) {
                    if (data.length <= maxDots) {
                        return <button>{i + 1}</button>;
                    }
                    if (i < maxDots) {
                        return <button>{i + 1}</button>;
                    }
                    if (i === maxDots) {
                        return <span>...</span>;
                    }
                    return 10;
                }
            } catch (error) {

            }
        },
        beforeChange: (current, next) => setCurrentSlide(next),
        appendDots: (dots) => {
            return (
                <div id="courses-nav" className='absolute top-0 rounded-lg flex flex-row items-center justify-center'>
                    <div
                        className="hover:cursor-pointer"
                        onClick={() => slider?.current?.slickPrev()}
                    >
                        <Arrow rotate="rotate-180" />
                    </div>
                    <ul id="coursesDots" className='m-0 hidden md:block'>{dots.slice(0, maxDots)}</ul>
                    <div
                        className="hover:cursor-pointer"
                        onClick={() => slider?.current?.slickNext()}
                    >
                        <Arrow rotate={""} />
                    </div>
                </div>
            )
        }
    };

    return (
        <section className='container mx-auto md:px-[100px]'>
            <div className='rounded-2xl p-web md:shadow-2xl relative overflow-hidden text-center md:text-left'>
                <div className='text-5xl md:text-6xl z-20 font-semibold pb-5'>
                    FEATURED EXAMS
                </div>
                <p className='text-base'>
                    Scholar.AI is an AI-powered subjective answer analysis tool that can help students to improve their subjective answers and prepare for competitive exams better.
                </p>
                <div className='pt-10'>
                    {Array.isArray(data) && data.length > 0 && <Slider {...settings} ref={slider} className='bg-white z-20 rounded-2xl px-4 pb-10 shadow-courses h-full'>
                        {data.map((item, index) => (
                            <CourseCard key={`coursecard${index}`} courseImage={course} authorImage={person} authorName={item.authorName} authorRole={item.authorRole} courseDescription={item.courseDescription} rating={item.rating} timing={item.timing} set_name={item.set_name} task_type={item.task_type} exam_id={item.exam_id} userId={user && user.id ? user.id : ""} />
                        ))}
                    </Slider>}
                </div>
                <Blob rotate={"rotate-45"} scale="scale-[1.8]" position={"invisible lg:visible -top-[120px] right-10"} />
                <Blob rotate={"rotate-90"} scale="scale-[1.5]" position={"hidden md:block -bottom-20 left-0"} />
                <Blob rotate={"rotate-180"} scale="scale-[1.1]" position={"hidden md:block -bottom-10 right-0"} />
            </div>

        </section>
    )
}
