import React, { useContext, useEffect, useState } from 'react'

// import arrowDown from "../../assets/images/arrow_down.png";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import course from "../../images/courses.jpg"
import person from "../../images/person.jpg"
import { apiCall } from '../../api/login';
import CourseCard from '../../components/ui/CourseCard';
import Pagination from '../../components/ui/Pagination';
import { UserContext } from '../../context/userContext';

export default function ExploreTests() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [displayData, setDisplayData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [difficulty, setDifficulty] = useState("All");
    const [menuOpen, setMenuOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const { user } = useContext(UserContext);

    const itemsPerPage = 8;
    console.log("user: ", user)

    useEffect(() => {
        const getData = async () => {
            try {
                const response = (await apiCall.get("get_exam_sets")).data
                // console.log("reponse questions: ", response)
                const dummyArr = response.map((item) => ({
                    courseImage: { course },
                    authorImage: { person },
                    authorName: "Jane Doe",
                    authorRole: "Software Developer",
                    courseDescription: item.set_name,
                    rating: 3,
                    timing: item.time,
                    set_name: item.set_name,
                    task_type: item.task_type,
                    exam_id: item.exam_id
                }));
                // console.log("Dummy array: ", dummyArr)
                setData(dummyArr);
                setDisplayData(dummyArr);
                const firstPage = dummyArr.filter((_i, index) => index < 8);
                setCurrentData(firstPage);
                const _totalpages = Math.ceil(dummyArr.length / itemsPerPage)
                setTotalPages(_totalpages)
                // console.log("reponse: ", response);
            } catch (error) {
                console.log("error fetching question: ", error);
            }
        }
        getData();
    }, [])

    useEffect(() => {
        const _totalpages = Math.ceil(displayData.length / itemsPerPage)
        setTotalPages(_totalpages)
    }, [displayData])

    useEffect(() => {
        if (difficulty !== "All") {
            // const difficultyType = difficulty.toLowerCase();
            const newData = data.filter((item) => item.set_name.includes(difficulty))
            setDisplayData(newData);
        } else {
            setDisplayData(data);
        }
    }, [data, difficulty])

    useEffect(() => {
        const currentItems = displayData.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
        setCurrentData(currentItems);
    }, [currentPage, data, displayData])

    return (
        <div>
            <section className="px-5 pb-10">
                <div className='text-[#2A4563] text-2xl font-bold pb-5'>
                    Explore tests
                </div>
                <div className='flex justify-between items-center'>
                    <div className='relative text-[#2A4563] text-base font-semibold pb-5 flex justify-start items-center gap-2' >
                        <span>Difficulty: </span>
                        <span onClick={() => setMenuOpen(!menuOpen)} className=' cursor-pointer bg-primary-blue text-white rounded-md px-3 flex items-center py-1 gap-2'>{difficulty}
                            {/* <img src={arrowDown} alt="arrow down" className="w-4 h-4 " /> */}
                            {menuOpen ? <ChevronUpIcon className="w-4 h-4 text-white" /> :
                                <ChevronDownIcon className="w-4 h-4 text-white" />}
                        </span>

                        <div
                            className={`${menuOpen ? "absolute top-[30px] " : "hidden"
                                }`}
                        >
                            <div className=" bg-white p-2 rounded-md shadow-lg text-sm w-max flex flex-col gap-2 text-[#636363]">
                                <div className="cursor-pointer pl-2 pr-4 py-0.5 hover:bg-primary-blue rounded-md hover:bg-opacity-10" onClick={() => {
                                    setDifficulty("All")
                                    setMenuOpen(!menuOpen)
                                }}>All</div>
                                <div className="cursor-pointer pl-2 pr-4 py-0.5 hover:bg-primary-blue rounded-md hover:bg-opacity-10" onClick={() => {
                                    setDifficulty("Easy")
                                    setMenuOpen(!menuOpen)
                                }}>Easy</div>
                                <div className="cursor-pointer pl-2 pr-4 py-0.5 hover:bg-primary-blue rounded-md hover:bg-opacity-10" onClick={() => {
                                    setDifficulty("Medium")
                                    setMenuOpen(!menuOpen)
                                }}>Medium</div>
                                <div className="cursor-pointer pl-2 pr-4 py-0.5 hover:bg-primary-blue rounded-md hover:bg-opacity-10" onClick={() => {
                                    setDifficulty("Hard")
                                    setMenuOpen(!menuOpen)
                                }}>HARD</div>
                            </div>
                        </div>
                    </div>
                    {totalPages > 0 && Array.isArray(displayData) && displayData.length > 0 &&
                        <Pagination totalPages={totalPages} data={displayData} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    }
                </div>
                <div className="bg-white p-5 md:px-10 shadow-md rounded-md flex flex-wrap">
                    {Array.isArray(currentData) && currentData.length > 0 && currentData.map((item) => (
                        <div key={item.exam_id} className='w-1/4 h-full'>
                            <CourseCard section="exploretests" courseImage={course} authorImage={person} authorName={item.authorName} authorRole={item.authorRole} courseDescription={item.courseDescription} rating={item.rating} timing={item.timing} set_name={item.set_name} task_type={item.task_type} exam_id={item.exam_id} userId={user && user.id ? user.id : ""} />
                        </div>
                    ))}
                </div>
            </section >
        </div >
    )
}
